<script context="module" lang="ts">
  export type Category = {
    name: string;
    id: number;
    active?: boolean;
  };
</script>

<script lang="ts">  
  import type { SwiperOptions } from 'swiper/types';
  import { Swiper } from 'swiper';
  import { Navigation } from 'swiper/modules';
  import { onMount } from 'svelte';
  
  import type { Link } from '~/components/field-utils';
  import { getStoresContext, type StoresKey } from '~/stores';
  import type { ManageAPI } from "~/manage-api/types";
  import { filterOffersByStream, reduceByAvailableStreams } from '~/util/offer';
  import { asLink, type ReverseRouter } from '~/reverse-router';
  import Modal from './Modal.svelte';
  import OfferCardModal from './OfferCardModal.svelte';

  export let title: string;
  export let link: Link;
  export let categories: Category[];
  export let request_store_key: StoresKey;

  const { store, offers, T, site, scdata, reverse_router } = getStoresContext(request_store_key);
  $: t = $T;
  
  let swiperEl: HTMLElement;
  let swiperInstance: Swiper | null = null;
  let isInitialized = false;
  let storeOffers: undefined | ManageAPI.Offer[];
  $: if ($store && $offers && $offers.length) {
    storeOffers = $offers.filter((offer) => offer.store?.id == $store?.id);
  }

  // If the type ID field is empty in Liferay, the category shouldn't be displayed:
  $: categories = categories.filter(x => x.id !== 0 || (x.id === 0 && (x.name === "Kaikki" || x.name === "All")));

  let filtered_list: ManageAPI.Offer[] = [];
  $: picked_list = [...$offers];
  $: available_categories = reduceByAvailableStreams(categories, picked_list);
  $: active_category = available_categories?.find(category => category.active);
  
  $: if (picked_list && !$store) {
    if (!active_category || active_category.id === 0) {
      filtered_list = picked_list;
    } else {
      filtered_list = filterOffersByStream(picked_list, active_category.id);
    }
  } else if (storeOffers && storeOffers.length) {
    filtered_list = storeOffers;
  }

  const swiperParams: SwiperOptions = {
    slidesPerView: 'auto',
    loop: true,
    watchSlidesProgress: true,
    modules: [Navigation]
  };

  // Wait for both element and data
  $: if (swiperEl && filtered_list.length && !isInitialized) {
    initSwiper();
  }

  function initSwiper() {
    // Destroy existing instance if any
    if (swiperInstance) {
      swiperInstance.destroy(true, true);
    }

    // Create new instance
    swiperInstance = new Swiper(swiperEl, swiperParams);
    isInitialized = true;
  }

  onMount(() => {
    return () => {
      if (swiperInstance) {
        swiperInstance.destroy(true, true);
      }
    };
  });

  function next() {
    if (swiperInstance) swiperInstance.slideNext();
  }

  function prev() {
    if (swiperInstance) swiperInstance.slidePrev();
  }

  let id = 0;
  $: focusedOffer = id ? $offers.find(offer => offer.id === id) : undefined;

  function switchCategory(category: Category) {
    if (active_category) active_category.active = false;
    active_category = category;
    category.active = true;
  }
</script>

{#if filtered_list && filtered_list.length}
<div class="olifts d-md-flex flex-md-row" class:background-color-lift={$store}>
  {#if picked_list && !$store}
    <div class="categories px-4 flex-shrink-0">
      <h2 class="mb-4">{title}</h2>
      {#each available_categories as category}
        <button class="stream-toggle" class:active={category === active_category} on:click={() => switchCategory(category)}>
          {category.name}
        </button>
      {/each}
      <a class="btn btn-primary mt-3 mb-5 mt-md-5 mb-md-3" href={link.href} target={link.target}>
        {link.text}
      </a>
    </div>
  {:else if $store && filtered_list}
    <div class="store-title">{t`stores.excitingOffers`}</div>
  {/if}
  <div class="swiper" bind:this={swiperEl}>
    <div class="swiper-wrapper">
      {#each filtered_list as item}
        <div class="swiper-slide item" 
             on:click={(e) => {
               e.preventDefault();
               e.stopPropagation();
               id = item.id;
             }}
             on:keypress={(e) => {
               e.preventDefault();
               e.stopPropagation();
               if (e.key === 'Enter') id = item.id;
             }}
             role="button"
             tabindex="0">
          <img src={item.images?.at(0)} alt="" />
          <div class="by mt-3">{item.store?.name || $site?.name}</div>
          <div class="desc">{item.title}</div>
        </div>
      {/each}
    </div>
    
    <!-- Desktop navigation -->
    <div class="swiper-nav-next d-none d-md-block" on:click={next} on:keypress={next} role="button" tabindex="0">
      <span class="arrow-icon"></span>
    </div>
  </div>

  <!-- Mobile navigation -->
  <div class="mobile-navigation d-md-none">
    <button class="nav-button" on:click={prev} aria-label="Previous">
      <span class="arrow-icon"></span>
    </button>
    <button class="nav-button" on:click={next} aria-label="Next">
      <span class="arrow-icon"></span>
    </button>
  </div>
</div>
  
<Modal bind:id>
  {#if id && focusedOffer}
    <OfferCardModal 
      {request_store_key} 
      offer={focusedOffer} 
      isCampaignPage={false}
    />
  {/if}
</Modal>
{/if}

<style lang="scss">
  .olifts {
    position: relative;

    a {
      text-decoration: none;

      &:hover {
        background-color: unset;
      }
    }

    &.background-color-lift {
      background-color: var(--color-brand-primary-lighten-6);
      padding: 80px 48px;
    }

    // Swiper items size and layout
    --item-width: 93vw;
    --gap: min(1vw, 8px);
    --nxt-btn-gap: 1;

    .swiper {
      margin-left: calc(var(--container-py) * -1);
      margin-right: calc(var(--container-py) * -1);

      @media screen and (min-width: 1920px) {
        margin-right: 0;
      }

      @media screen and (max-width: 768px) {
        overflow: visible;
      }
    }

    .swiper-wrapper {
      display: flex;
    }

    .item {
      cursor: pointer;
      text-align: center;
      width: var(--item-width);
      margin-right: var(--gap);

      img {
        aspect-ratio: 1;
        width: 100%;
        object-fit: cover;
      }

      .by {
        text-transform: uppercase;
      }
      .store-name {
        &:hover {
          color: var(--color-brand-primary);
        }
      }
      .desc {
        font-weight: 600;

        &:after {
          content: '\00203a';
          margin-left: 4px;
        }
      }

      &:hover {
        color: var(--body-color);

        .desc {
          color: var(--color-brand-primary);
        }
      }
    }

    .swiper-nav-next {
      position: absolute;
      top: calc(var(--item-width) / 2);
      left: calc(
        var(--item-width) * var(--nxt-btn-gap) +
        var(--gap) * (var(--nxt-btn-gap) - 0.5)
      );
      width: 46px;
      height: 46px;
      transform: translate(-50%, -50%);
      z-index: 10;
      background: #fff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

      .arrow-icon {
        position: relative;
        width: 16px;
        height: 2px;
        background-color: var(--color-brand-primary);
        display: block;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        &::before,
        &::after {
          content: '';
          position: absolute;
          right: 1px;
          width: 10px;
          height: 2px;
          background-color: var(--color-brand-primary);
        }

        &::before {
          transform: rotate(45deg);
          transform-origin: right;
        }

        &::after {
          transform: rotate(-45deg);
          transform-origin: right;
        }
      }

      &:hover {
        background: var(--color-brand-primary-lighten-6);
      }
    }

    @media screen and (min-width: 768px) {
      --item-width: clamp(350px, 40vw, 420px);

      .swiper {
        margin-left: 0;
      }

      .categories {
        width: var(--item-width);
      }
    }

    @media screen and (min-width: 1024px) {
      --item-width: clamp(310px, 30vw, 420px);
      --nxt-btn-gap: 2;
    }

    @media screen and (min-width: 1440px) {
      --item-width: clamp(310px, 22vw, 420px);
      --nxt-btn-gap: 3;
    }
  }

  .categories {
    width: 100%;
    padding: 0 15px;

    h2 {
      margin-bottom: 4rem;
    }

    .stream-toggle {
      display: block;
      margin: 0 10px 10px 0;
      padding: 8px 24px;
      background-color: var(--color-brand-primary-lighten-6, #ddd);
      border: none;
      border-radius: 100px;
      -webkit-tap-highlight-color: transparent;
      -webkit-appearance: none;
      color: inherit;

      &.active {
        background-color: var(--color-brand-primary, #333);
        color: var(--color-action-primary-inverted);
      }
    }

    a.btn {
      margin: 3rem 0 5rem 0;
    }
  }

  .store-title {
    font-size: 28px;
    font-weight: 600;
    width: 70%;
  }

  a:hover {
    text-decoration: none;
  }

  .swiper-nav-next,
  .nav-button {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    padding: 0;

    .arrow-icon {
      position: relative;
      width: 16px;
      height: 2px;
      background-color: var(--color-brand-primary);

      &::before,
      &::after {
        content: '';
        position: absolute;
        right: 1px;
        width: 10px;
        height: 2px;
        background-color: var(--color-brand-primary);
      }

      &::before {
        transform: rotate(45deg);
        transform-origin: right;
      }

      &::after {
        transform: rotate(-45deg);
        transform-origin: right;
      }
    }
  }

  .mobile-navigation {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: 24px;
    padding: 0 24px;

    .nav-button {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .arrow-icon {
        position: relative;
        width: 16px;
        height: 2px;
        background-color: var(--color-brand-primary);

        &::before,
        &::after {
          content: '';
          position: absolute;
          right: 1px;
          width: 10px;
          height: 2px;
          background-color: var(--color-brand-primary);
        }

        &::before {
          transform: rotate(45deg);
          transform-origin: right;
        }

        &::after {
          transform: rotate(-45deg);
          transform-origin: right;
        }
      }

      &:first-child .arrow-icon {
        transform: rotate(180deg);
      }
    }
  }
</style>
